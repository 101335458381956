/* Libre Caslon Text */
@font-face {
    font-family: 'LibreCaslonText';
    src: url('../../assets/fonts/LibreCaslonText/LibreCaslonText-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LibreCaslonText';
    src: url('../../assets/fonts/LibreCaslonText/LibreCaslonText-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LibreCaslonText';
    src: url('../../assets/fonts/LibreCaslonText/LibreCaslonText-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* Fira Sans */
@font-face {
    font-family: 'Fira Sans';
    src: url('../../assets/fonts/FiraSans/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../assets/fonts/FiraSans/FiraSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../assets/fonts/FiraSans/FiraSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

/* Niageng */
@font-face {
    font-family: 'Niageng';
    src: url('../../assets/fonts/Niageng/NIAGENG.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
