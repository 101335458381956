/* Libre Caslon Text */
@font-face {
  font-family: "LibreCaslonText";
  src: url("../../assets/fonts/LibreCaslonText/LibreCaslonText-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LibreCaslonText";
  src: url("../../assets/fonts/LibreCaslonText/LibreCaslonText-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "LibreCaslonText";
  src: url("../../assets/fonts/LibreCaslonText/LibreCaslonText-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* Fira Sans */
@font-face {
  font-family: "Fira Sans";
  src: url("../../assets/fonts/FiraSans/FiraSans-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Fira Sans";
  src: url("../../assets/fonts/FiraSans/FiraSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Fira Sans";
  src: url("../../assets/fonts/FiraSans/FiraSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
/* Niageng */
@font-face {
  font-family: "Niageng";
  src: url("../../assets/fonts/Niageng/NIAGENG.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
a {
  text-decoration: none;
  color: inherit;
}

p,
span,
b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}
p.text-center,
span.text-center,
b.text-center,
strong.text-center,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center,
h6.text-center {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "LibreCaslonText";
}

p, span, b, strong {
  font-family: "Fira Sans";
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  /* &::-webkit-scrollbar { // Styles to change scrollbar in case of need
            width: .5rem;
        }

        &::-webkit-scrollbar-track {
          background-color: #4d9068;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $main-green;
            border: 1px solid $vi-turq;
            border-radius: 30px;
        } */
}

html {
  overflow-x: hidden;
}